import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { spacing } from "@material-ui/system";
import Buttonn from "../styles/Button";
import Colors from "../utils/helper/Colors";
import InterText from "../styles/Inter";
import { Alert as MuiAlert, RadioGroup, Radio, Chip } from "../styles/MUIOverwrite";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ShopifyLineItemForPortal, ShopifyProduct } from "../utils/helper/LineItemTypes";
import LatoText from "../styles/lato";
import { Grid as MuiGrid } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { analytics } from "../firebase";

const Alert = styled(MuiAlert)(spacing);

const ButtonVariant = styled(Buttonn)`
  border: ${(props) => (props.color === "primary" ? 2 : 1)}px solid
    ${(props) => {
      return props.disabled
        ? props.theme.bg_disabled
        : props.color === "primary"
        ? props.theme.main
        : props.theme.text ?? Colors.keepoala.grey.light;
    }};
  background-color: white !important;
  &:hover {
    .MuiButton-label {
      color: ${(props) => props.theme.main ?? Colors.keepoala.main} !important;
    }
  }
  .MuiButton-label {
    color: ${(props) => {
      return props.disabled
        ? props.theme.bg_disabled
        : props.color === "primary"
        ? props.theme.main
        : props.theme.text ?? Colors.keepoala.greytext; //COM
    }};
  }
`;

const VariantPreviewImage = styled.img`
width: 80%;
border-radius: 6px;
&.disabled{
filter: brightness(1.5) saturate(0);
`;

const RefundHandlerPre = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const RefundHandler = styled(RefundHandlerPre)(spacing);

const ProductFrame = styled.div`
  border: 1px solid #eaeaf0;
  border-radius: 5px;
  box-shadow: 1px 1px 0px 0px rgb(199, 199, 199);
  margin-bottom: 15px;
`;

const Product = styled(ProductFrame)`
  cursor: pointer;
  :hover {
    border-color: ${(props) => {
      return props.theme.main ?? Colors.keepoala.main;
    }};
    border-width: 3px;
    box-shadow: 1px 1px 0px 0px
      ${(props) => {
        return props.theme.main ?? Colors.keepoala.main;
      }};
  }
`;

const Grid = styled(MuiGrid)(spacing);

const GridLeft = styled(Grid)`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;
const GridRight = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  &.variant {
    background-color: ${(props) => props.theme.bg_disabled};
  }
`;

export const ArrowBackIconBack = styled(ArrowBackIcon)`
  color: ${(props) => {
    return props.theme.main ?? Colors.keepoala.black;
  }};
`;

function keymap(object) {
  return Object.keys(object).map((key) => object[key]);
}

function combineRecursive(...arrays: (string[] | undefined)[]): string[] {
  if (!arrays || arrays.length < 2) {
    throw new Error("Cannot combine less than 2 arrays.");
  }

  if (arrays.length > 2) {
    return combineRecursive(arrays[0], combineRecursive(...arrays.slice(1)));
  }

  // arrays.length is exactly 2
  const combinations: string[] = [];
  for (const word0 of arrays[0] ?? [""]) {
    for (const word1 of arrays[1] ?? [""]) {
      combinations.push(word0 + "XX%%XX" + word1);
    }
  }
  return combinations;
}

const optionsFromVariantGroup = (variant_group: ShopifyProduct[], quantity): string[][] => {
  const all_values = variant_group[0].options.sort((a, b) => a.position - b.position).map((x) => x.values);
  const all_options_array: string[][] = combineRecursive(all_values[0], all_values[1], all_values[2]).map((x: string) =>
    x.split("XX%%XX")
  );
  return all_options_array.filter((combination: string[]) => {
    return (
      variant_group
        .filter((product: ShopifyProduct) => (product.inventory_quantity ?? 1) >= quantity)
        .filter(
          (product: ShopifyProduct) =>
            (product.option1 === combination[0] || combination[0] === "") &&
            (product.option2 === combination[1] || combination[1] === "") &&
            (product.option3 === combination[2] || combination[2] === "")
        ).length > 0
    );
  });
};

export default function ReturnExchange(props: {
  line_item?: ShopifyLineItemForPortal;
  successHandlerEx: (product: (ShopifyProduct | undefined)[]) => void;
  quantity: number;
  backHandler?: () => void;
}) {
  const all_options = [1, 2, 3];
  const all_options_left = (position: number): number[] => {
    const internal_opts: number[] = JSON.parse(JSON.stringify(all_options));
    internal_opts.splice(internal_opts.indexOf(position), 1);
    return internal_opts;
  };
  const { line_item, successHandlerEx, quantity, backHandler } = props;

  const variant_groups = keymap(
    line_item?.exchange_products?.reduce((group, product) => {
      if (product !== undefined) {
        const { id } = product;
        group[id] = group[id] ?? [];
        group[id].push(product);
        return group;
      } else {
        return group;
      }
    }, {})
  );

  const { t, i18n } = useTranslation();
  const language = i18n.language.toLowerCase();

  const [open, setOpen]: [boolean, any] = useState(false);
  const [refresh, setRefresh]: [boolean, any] = useState(false);
  const [selectedProducts, setSelectedProducts]: [(ShopifyProduct | undefined)[] | undefined, any] = useState([]);
  const [leftQuantity, setLeftQuantity]: [number, any] = useState(quantity);
  const [selectedVariant, setSelectedVariant]: [
    {
      id: number;
      variant_id: number;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      image_preview: string;
    } | null,
    any
  ] = useState(null);
  const [selectedVariantIntermediate, setSelectedVariantIntermediate]: [
    {
      id: number;
      variant_id: number;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      image_preview: string;
    } | null,
    any
  ] = useState(null);

  const checkIt = (product: ShopifyProduct | null) => {
    if (product !== null) {
      const current_sel_product = selectedProducts;
      current_sel_product.push(product);
      setSelectedProducts(current_sel_product);
      setOpen(true);
      setLeftQuantity(leftQuantity - 1);
    }
  };

  const handleRefund = () => {
    const current_sel_product = selectedProducts;
    current_sel_product.push(undefined);
    setSelectedProducts(current_sel_product);
    setOpen(true);
    setLeftQuantity(leftQuantity - 1);
  };

  const handleClose = () => {
    setOpen(false);
    const current_sel_product = selectedProducts;
    current_sel_product.pop();
    setSelectedProducts(current_sel_product);
    setLeftQuantity(leftQuantity + 1);
  };

  const handleSuccess = () => {
    analytics.logEvent("returnfeedback_chose_exchange", {
      feature: "returnfeedback",
      event: "returnfeedback_chose_exchange",
    });
    if (leftQuantity === 0) {
      successHandlerEx(selectedProducts);
    } else {
      setOpen(false);
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      }, 700);
    }
  };

  const optionEnabled = React.useCallback(
    (option_position: number, option_value: string, all_options: string[][]): boolean => {
      if (selectedVariant !== undefined && selectedVariant !== null) {
        return (
          all_options
            // Filter out options array by the other two optiosn of the variant
            .filter((option_array: string[]) => {
              let pos = [1, 2, 3].filter((x) => x !== option_position);
              return (
                (option_array[pos[0] - 1] === "" || option_array[pos[0] - 1] === selectedVariant["option" + pos[0]]) &&
                (option_array[pos[1] - 1] === "" || option_array[pos[1] - 1] === selectedVariant["option" + pos[1]])
              );
            })
            // choose the position we need
            .map((x) => x[option_position - 1])
            // check if the current value is in
            .includes(option_value)
        );
      } else {
        return all_options.some((x) => x[option_position - 1].toLowerCase() === option_value.toLowerCase());
      }
    },
    [selectedVariant]
  );

  const handleVariantPick = (option_position: number, value: string, product_id: string) => {
    console.log(option_position, value, product_id);
    const filtered_items = (line_item?.exchange_products ?? [])
      .filter((x: ShopifyProduct | undefined): boolean => x !== undefined)
      .filter((product: ShopifyProduct | undefined) =>
        product === undefined ? false : (product.inventory_quantity ?? 1) >= quantity
      )
      .filter((x: ShopifyProduct | undefined) =>
        x === undefined ? false : x.id.toString() === product_id.toString() && x["option" + option_position] === value
      );
    console.log(filtered_items);
    if (selectedVariant !== undefined && selectedVariant !== null) {
      const double_filter = filtered_items.filter((item) => {
        return (
          item !== undefined &&
          all_options_left(option_position).every(
            (index) => selectedVariant["option" + index] === item["option" + index]
          )
        );
      });
      if (double_filter.length !== 0) {
        setSelectedVariantIntermediate(double_filter[0]);
      } else {
        setSelectedVariantIntermediate(filtered_items[0]);
      }
    } else {
      setSelectedVariantIntermediate(filtered_items[0]);
    }
  };

  useEffect(() => {
    if (selectedVariantIntermediate !== null) {
      console.log(selectedVariantIntermediate);
      setSelectedVariant(selectedVariantIntermediate);
      setSelectedVariantIntermediate(null);
    }
  }, [selectedVariantIntermediate]);

  return (
    <>
      <LatoText fontSize="xl" mb={2} align="center">
        {t("Which product would you like as an exchange?")}
        <br />
        <InterText size="l" align="center">
          <em>
            {line_item !== undefined
              ? t("for") +
                " " +
                line_item?.title +
                (line_item?.variant_title !== undefined &&
                line_item?.variant_title !== null &&
                line_item?.variant_title !== "Default Title"
                  ? "(" + line_item?.variant_title + ")"
                  : "")
              : ""}
          </em>
        </InterText>
        <br />
        {quantity > 1 ? leftQuantity + " / " + quantity + " " + t("left to choose") : ""}
      </LatoText>
      {line_item === undefined ? (
        <Alert severity="error">
          {t("Problem with the exchange")} .{t("Please report this to Keepoala")}
        </Alert>
      ) : (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t("Are you sure?")}</DialogTitle>
            <DialogContent>
              {selectedProducts[selectedProducts.length - 1] !== undefined ? (
                <InterText>
                  {t("Are you sure you want to exchange against")}
                  <br />
                  <br />
                  <b>
                    {(selectedProducts[0] ?? { title: "" }).title +
                      " - " +
                      // Only show variant title in case this is a real varian
                      ((
                        selectedProducts[0] ?? {
                          options: [{ values: [] }],
                          variant_title: "",
                        }
                      ).options[0].values.length >= 1 &&
                      (
                        selectedProducts[0] ?? {
                          options: [{ values: [] }],
                          variant_title: "",
                        }
                      ).options[0].values[0] !== "Default Title" &&
                      selectedProducts[0] !== undefined
                        ? selectedProducts[0]?.variant_title
                        : "")}
                  </b>
                </InterText>
              ) : (
                <InterText>{t("Are you sure you only want your money back")}?</InterText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("no")}</Button>
              <Button onClick={() => handleSuccess()} autoFocus>
                {t("Yes")}
              </Button>
            </DialogActions>
          </Dialog>
          {refresh
            ? null
            : variant_groups.map((variant_group) => {
                return variant_group.length > 1 ? (
                  <>
                    <ProductFrame>
                      <Grid container>
                        <GridLeft item md={4} p={1}>
                          <VariantPreviewImage
                            src={(selectedVariant ?? variant_group[0])["image_preview"]?.replace("100x100", "200x200")}
                            key={variant_group[0].id}
                          />
                          <InterText size="l" weight={600} align="center">
                            {variant_group[0].title}
                          </InterText>
                        </GridLeft>
                        <GridRight className="variant" item md={8} p={2}>
                          {variant_group[0].options
                            .sort((a, b) => a.position - b.position)
                            .map((option: { name: string; values: string[]; position: 1 | 2 | 3 | undefined }) => {
                              // for each option
                              return (
                                <>
                                  <InterText size="l" weight={600}>
                                    {option.name}
                                  </InterText>
                                  {
                                    // Check if we can display buttons or we need a radio group
                                    option.values.every((option_value: string) => option_value.length > 2) ? (
                                      // Radio Group needed as options are Titles / long phrases
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={
                                          (selectedVariant ?? {})[
                                            "option" + (option.position === undefined ? 1 : option.position)
                                          ] ?? ""
                                        }
                                        onChange={(event) => {
                                          handleVariantPick(
                                            option.position === undefined ? 1 : option.position,
                                            event.target.value,
                                            variant_group[0].id
                                          );
                                        }}>
                                        {option.values.map((option_value: string) => {
                                          return (
                                            <FormControlLabel
                                              value={option_value}
                                              control={<Radio />}
                                              label={option_value}
                                              disabled={
                                                !optionEnabled(
                                                  option.position === undefined ? 1 : option.position,
                                                  option_value,
                                                  optionsFromVariantGroup(variant_group, quantity)
                                                )
                                              }
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    ) : (
                                      // Show Buttons for short options
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                        }}>
                                        {option.values.map((option_value: string) => {
                                          // check if there are items with that option
                                          // else disable the button
                                          const is_enabled = optionEnabled(
                                            option.position === undefined ? 1 : option.position,
                                            option_value,
                                            optionsFromVariantGroup(variant_group, quantity)
                                          );
                                          // enable the current variant
                                          const is_active =
                                            selectedVariant !== null &&
                                            selectedVariant !== undefined &&
                                            (selectedVariant["id"] ?? 0) === variant_group[0].id &&
                                            (selectedVariant[
                                              "option" + (option.position === undefined ? 1 : option.position)
                                            ] ?? "") === option_value;
                                          return (
                                            <ButtonVariant
                                              mr={2}
                                              mb={2}
                                              size="small"
                                              disabled={!is_enabled}
                                              color={is_active ? "primary" : "secondary"}
                                              onClick={() => {
                                                handleVariantPick(
                                                  option.position === undefined ? 1 : option.position,
                                                  option_value,
                                                  variant_group[0].id
                                                );
                                              }}>
                                              {option_value}
                                            </ButtonVariant>
                                          );
                                        })}
                                      </div>
                                    )
                                  }
                                </>
                              );
                            })}
                          <Buttonn
                            color="secondary"
                            disabled={selectedVariant == null || selectedVariant === undefined}
                            variant="contained"
                            onClick={() => checkIt(selectedVariant)}>
                            {t("Choose this")}
                          </Buttonn>
                        </GridRight>
                      </Grid>
                    </ProductFrame>
                  </>
                ) : (
                  variant_group
                    .filter((product: ShopifyProduct) => (product.inventory_quantity ?? 1) >= quantity)
                    .map((product: ShopifyProduct) => {
                      return (
                        <Product key={product.variant_id} onClick={() => checkIt(product)}>
                          <Grid container>
                            <Grid item md={4} p={1} alignContent={"center"} display={"flex"} justifyContent={"center"}>
                              <VariantPreviewImage
                                src={product.image_preview?.replace("100x100", "200x200")}
                                key={product.id}
                              />
                            </Grid>
                            <GridRight item md={8} p={2}>
                              <InterText>{product.title}</InterText>
                              {product.options[0].values.length > 1 ? (
                                <>
                                  {product.options[0].values.length <= 1 ? null : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}>
                                      {product.option1 !== null ? (
                                        <Chip label={product.option1} color="primary" mr={1} />
                                      ) : null}
                                      {product.option2 !== null ? (
                                        <Chip label={product.option2} color="primary" mr={1} />
                                      ) : null}
                                      {product.option3 !== null ? (
                                        <Chip label={product.option3} color="primary" mr={1} />
                                      ) : null}
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </GridRight>
                          </Grid>
                        </Product>
                      );
                    })
                );
              })}
        </>
      )}

      {line_item?.policy.allowed.refund && (
        <RefundHandler mt={4} mb={4}>
          <InterText align="center" mb={1}>
            {t("You did not find the right option or do not want an exchange")}
          </InterText>
          <Buttonn variant="contained" size="small" color="primary" onClick={() => handleRefund()}>
            {t("I do not want an exchange")}. {t("Please refund me")}.
          </Buttonn>
        </RefundHandler>
      )}
      <br />
      {backHandler !== undefined && (
        <Buttonn mt={2} onClick={backHandler}>
          <ArrowBackIconBack />
        </Buttonn>
      )}
      {/* Show the exchange alert again */}
      {line_item !== undefined &&
      line_item.policy.info[language] !== undefined &&
      line_item.policy.info[language] !== null &&
      line_item.policy.info[language] !== "" ? (
        <Alert mb={2} mt={2} severity="warning">
          <InterText>{line_item.policy.info[language]}</InterText>
        </Alert>
      ) : null}
    </>
  );
}
